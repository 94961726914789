import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {TextField} from '../../../_ui/form';
import Button from '../../../_ui/button';
import {ReactComponent as HelpIcon} from '../../../../_icons/help.svg';
import {ReactComponent as NeuroIcon} from '../../../../_icons/pathology/neuro.svg';
import {ReactComponent as MuscuIcon} from '../../../../_icons/pathology/muscu.svg';
import {ReactComponent as OsteoIcon} from '../../../../_icons/pathology/osteo.svg';
import {ReactComponent as OtherIcon} from '../../../../_icons/pathology/other.svg';
import {ReactComponent as EditIcon} from '../../../../_icons/edit.svg';
import {ReactComponent as GestureIcon} from '../../../../_icons/gesture.svg';
import {ReactComponent as ConditionsIcon} from '../../../../_icons/conditions.svg';
import {ReactComponent as ChecklistIcon} from '../../../../_icons/checklist.svg';
import {ReactComponent as NavigateBeforeIcon} from '../../../../_icons/navigate_before.svg';
import {ReactComponent as ThumbDownIcon} from '../../../../_icons/thumb_down.svg';
import {ReactComponent as ThumbUpIcon} from '../../../../_icons/thumb_up.svg';
import {ReactComponent as FrontIcon} from '../../../../_icons/front.svg';
import {ReactComponent as BackIcon} from '../../../../_icons/back.svg';
import {ReactComponent as HeadIcon} from '../../../../_icons/head.svg';
import {ReactComponent as FootIcon} from '../../../../_icons/foot.svg';
import {ReactComponent as EventIcon} from '../../../../_icons/pathology_detail/event.svg';
import {ReactComponent as RelevantObservationIcon} from '../../../../_icons/pathology_detail/relevant_observation.svg';
import {ReactComponent as PositiveTestIcon} from '../../../../_icons/pathology_detail/positive_test.svg';
import {ReactComponent as SymptomReproductionIcon} from '../../../../_icons/pathology_detail/symptom_reproduction.svg';
import {ReactComponent as FlashOnIcon} from '../../../../_icons/flash_on.svg';
import {
  APIBodyPart,
  APIDiagnostic,
  APIPathology,
  APIPathologyCategory
} from '../../../../_services/api/_helpers/api-types';
import {useDiagnostic} from '../../../../_hooks/diagnostic/use-diagnostics';
import {useNavigate, useParams} from 'react-router-dom';
import {DiagnosticAPIService} from '../../../../_services/api';
import {ROUTES} from '../../../../router/routes';
import BottomBar from '../../../_ui/bottom-bar';
import Human from '../../../_ui/human';
import {useBodyParts} from '../../../../_hooks/body-part/use-body-parts';
import groupBy from '../../../../_services/utils/group-by';
import MascotModal from '../../../_ui/mascot-modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100svh;
  box-sizing: border-box;
  align-items: center;
  padding: 0 20px 100px 20px;

  height: calc(100svh - 86px);
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 24px 12px 12px;
  align-items: center;
  gap: var(--2, 8px);
  border-radius: var(--L, 24px);
  box-sizing: border-box;
`;

const pathologyCategoriesProps = [
  {
    label: 'neurology',
    Icon: NeuroIcon,
    title: 'Neurologie',
    menuTitle: 'Neuro',
    backgroundColorPrimary: '#F0FAFF',
    backgroundColorSecondary: '#D7F0FC',
    backgroundColorButton: '#B0D8EB',
  },
  {
    label: 'musculotendinous',
    Icon: MuscuIcon,
    title: 'Musculo-tendineux',
    menuTitle: 'Musc',
    backgroundColorPrimary: '#FFF7F5',
    backgroundColorSecondary: '#FFE7DE',
    backgroundColorButton: '#FFB299',
  },
  {
    label: 'osteoarticular',
    Icon: OsteoIcon,
    title: 'Ostéo-articulaire',
    menuTitle: 'Os-Art',
    backgroundColorPrimary: '#FFFCE5',
    backgroundColorSecondary: '#FCF4C0',
    backgroundColorButton: '#F7E76F',
  },
  {
    label: 'other',
    Icon: OtherIcon,
    title: 'Autre',
    menuTitle: 'Autre',
    backgroundColorPrimary: '#F3FCF7',
    backgroundColorSecondary: '#DAF7EE',
    backgroundColorButton: '#A2EBC5',
  },
];

const HeaderTitle = styled.h1`
  color: var(--Text-primary, #1D1D1B);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  /* Mobile/Headline */
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;

const BackButton = styled.span`
  color: var(--Text-neutral-5, #8E8E8E);
  font-feature-settings: 'liga' off, 'clig' off;

  /* Mobile/Body */
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;
const Header = ({id, title}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        paddingTop: 10,
        paddingBottom: 10,
        width: 'calc(100% + 40px)',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <HeaderTitle>{title}</HeaderTitle>
      </div>
      <EditIcon
        style={{
          position: 'absolute',
          right: 20,
        }}
        onClick={() => navigate(ROUTES.DIAGNOSTICS(id))}
      />
      <BackButton
        style={{
          position: 'absolute',
          left: 20,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => navigate(-1)}
      >
        <NavigateBeforeIcon/>
        {/*Quitter*/}
      </BackButton>
    </div>
  );
};

const DrawVue = ({diagnostic}: {diagnostic: APIDiagnostic}) => {
  const pictureRef = useRef();
  const [bodyPart, setBodyPart] = useState<APIBodyPart | null>(null);
  const {bodyParts} = useBodyParts();
  const diagnosticVersion = diagnostic.diagnostic_versions?.[0];
  const findImageStateEditor = (bodyPartToFind) => (
    diagnosticVersion.payload.
    find((obj) => obj.body_part_id === bodyPartToFind?.id)?.
      image_state_editor
  );
  const currentBodyPart = bodyPart || bodyParts?.list?.find(bp => findImageStateEditor(bp)) || bodyParts?.list?.[0];

  const imageStateEditor = findImageStateEditor(currentBodyPart);
  const activeStyle = {
    backgroundColor: '#ADE82F',
    borderRadius: 16,
    height: 52,
    width: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const inactiveStyle = (disabled) => ({
    backgroundColor: disabled ? '#FAFAFA' : '#F5F5F5',
    color: disabled ? '#DADADA' : '#636360',
    borderRadius: 16,
    height: 52,
    width: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const menuItems = [
    {
      Icon: FrontIcon,
      external: false,
      link: '#front',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'front');
        if (findImageStateEditor(itemBodyPart)) {
          setBodyPart(itemBodyPart);
        }
      },
      iconSize: {width: 40, height: 40},
      style: ({isActive}) => {
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'front');
        const disabled = !findImageStateEditor(itemBodyPart);
        return (
          isActive && currentBodyPart?.label === itemBodyPart?.label ? activeStyle : inactiveStyle(disabled)
        )
      },
    },
    {
      Icon: BackIcon,
      external: false,
      link: '#back',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'back');
        if (findImageStateEditor(itemBodyPart)) {
          setBodyPart(itemBodyPart);
        }
      },
      style: ({isActive}) => {
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'back');
        const disabled = !findImageStateEditor(itemBodyPart);
        return (
          isActive && currentBodyPart?.label === itemBodyPart?.label ? activeStyle : inactiveStyle(disabled)
        )
      },
    },
    {
      Icon: HeadIcon,
      external: false,
      // link: '#face',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'face');
        if (findImageStateEditor(itemBodyPart)) {
          setBodyPart(itemBodyPart);
        }
      },
      style: ({isActive}) => {
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'face');
        const disabled = !findImageStateEditor(itemBodyPart);
        return (
          isActive && currentBodyPart?.label === itemBodyPart?.label ? activeStyle : inactiveStyle(disabled)
        )
      },
    },
    {
      Icon: FootIcon,
      external: false,
      // link: '#foot',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'foot');
        if (findImageStateEditor(itemBodyPart)) {
          setBodyPart(itemBodyPart);
        }
      },
      style: ({isActive}) => {
        const itemBodyPart = bodyParts?.list?.find(bp => bp.label === 'foot');
        const disabled = !findImageStateEditor(itemBodyPart);
        return (
          isActive && currentBodyPart?.label === itemBodyPart?.label ? activeStyle : inactiveStyle(disabled)
        )
      },
    },
  ];
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <h1>Localisation clinique</h1>
      <BottomBar
        style={{
          width: '100%',
          height: null,
          background: 'none',
          boxShadow: 'none',
          border: 'none',
          padding: '0 14px',
          justifyContent: 'space-between',
        }}
        items={menuItems}
      />
      {currentBodyPart && (
        <div
          style={{
            flex: 1,
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {currentBodyPart?.id && (
            <Human
              key={currentBodyPart.id}
              ref={pictureRef}
              imageStateEditor={imageStateEditor}
              disabled={true}
              bodyPart={currentBodyPart}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ConditionsVue = ({diagnostic}: {diagnostic: APIDiagnostic}) => {
  const [pathologyCategory, setPathologyCategory] = useState<APIPathologyCategory | null>('neurology');
  const [openModal, setOpenModal] = useState<string | null>(null);
  const more = !!pathologyCategory;
  const diagnosticVersion = diagnostic.diagnostic_versions?.[0];

  let pathologiesResult = diagnosticVersion.computed.
    filter(r => r.pathology.category === pathologyCategory || pathologyCategory === null).
    map(r => ({
      commons_pathology_details_count: diagnosticVersion.pathology_details.filter(pd => r.pathology.pathology_details.map(a => a.id).includes(pd.id)).length,
      total_pathology_details_count: r.pathology.pathology_details.length,
      result: r,
    }));

  if (!diagnosticVersion.quick) {
    pathologiesResult = pathologiesResult.
      sort((a, b) => b.commons_pathology_details_count - a.commons_pathology_details_count).
      slice(0, 5);
  }

  const activeStyle = (backgroundColorButton) => ({
    backgroundColor: backgroundColorButton,
    borderRadius: 16,
    fontSize: 11,
  });

  const inactiveStyle = (backgroundColorButton) => ({
    backgroundColor: backgroundColorButton,
    color: '#D9D9D9',
    borderRadius: 16,
    fontSize: 11,
  });

  const menuItems = pathologyCategoriesProps.map(pathologyCategoryProps => ({
    label: pathologyCategoryProps.label,
    Icon: (props) => (
      <div
        style={{
          height: 54,
          width: 54,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <pathologyCategoryProps.Icon {...props}/>
        {pathologyCategoryProps.menuTitle}
      </div>
    ),
    external: false,
    link: `#${pathologyCategoryProps.label}`,
    target: undefined,
    onClick: (e) => {
      e.preventDefault();
      setPathologyCategory(pathologyCategoryProps.label as APIPathologyCategory)
    },
    iconSize: {width: 24, height: 24},
    style: () => (
      pathologyCategory === pathologyCategoryProps.label
        ? activeStyle(pathologyCategoryProps.backgroundColorButton)
        : inactiveStyle(pathologyCategoryProps.backgroundColorSecondary)
    ),
  }));

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
          color: '#1D1D1D',
        }}
      >
        {diagnosticVersion.quick && <FlashOnIcon/>}
        <h1>{diagnosticVersion.quick ? "Diagnostic Éclair" : "Ton résultat"}</h1>
        <HelpIcon
          onClick={() => setOpenModal(diagnosticVersion.quick ? 'helpModalQuick' : 'helpModal')}
        />
      </div>
      <div
        style={{
          flex: 1,
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 10,
          boxSizing: 'border-box',
        }}
      >
        {more && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: '0px 16px',
              marginBottom: 10,
            }}
          >
            {
              menuItems.map((item, index) => (
                <div
                  key={index}
                  style={item.style()}
                  onClick={() => setPathologyCategory(item.label as APIPathologyCategory)}
                >
                  <item.Icon width={24} height={24}/>
                </div>
              ))
            }
          </div>
        )}
        {
          pathologiesResult.map((resultItem, index) => (
          <Item
            key={index}
            style={{
              padding: diagnosticVersion.quick ? '20px 24px' : '12px 24px 12px 12px',
              backgroundColor: pathologyCategoriesProps.find(p => p.label === resultItem.result.pathology.category).backgroundColorSecondary,
            }}
          >
            {!diagnosticVersion.quick && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 12,
                  width: 42,
                  height: 42,
                  flexShrink: 0,
                  backgroundColor: pathologyCategoriesProps.find(p => p.label === resultItem.result.pathology.category).backgroundColorButton,
                  fontWeight: 600,
                }}
              >
                {resultItem.commons_pathology_details_count}/{resultItem.total_pathology_details_count}
              </div>
            )}
            {resultItem.result.pathology.text}
          </Item>
        ))}
        <span
          style={{marginTop: 10}}
          onClick={() => setPathologyCategory(prev => prev === null ? 'neurology' : null)}
        >
          {more ? "Voir moins" : "Voir plus"}
        </span>
      </div>
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={() => setOpenModal(null)}
        translationKey={`diagnosticResult.${openModal}`}
      />
    </>
  );
};

const pathologyDetailCategoriesProps = [
  {
    label: 'event',
    Icon: EventIcon,
    title: 'Antécédents',
  },
  {
    label: 'symptom_reproduction',
    Icon: SymptomReproductionIcon,
    title: 'Reproduction des symptomes',
  },
  {
    label: 'relevant_observation',
    Icon: RelevantObservationIcon,
    title: 'Observations pertinentes',
  },
  {
    label: 'positive_test',
    Icon: PositiveTestIcon,
    title: 'Tests positifs',
  },
];

const SecondaryTitle = styled.h2`
  color: var(--Text-primary, #1D1D1B);
  font-feature-settings: 'liga' off, 'clig' off;
  /* Mobile/Title3 */
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  letter-spacing: -0.2px;
`;

const ListItem = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 21px;
  color: #393937;
  font-weight: 400;
  width: 100%;
`;
const List = styled.div`
  display: flex;
  padding: 0px var(--M, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--2, 8px);
  align-self: stretch;
  box-sizing: border-box;
`;

const ChecklistVue = ({diagnostic}) => {
  const [pathologyCategory, setPathologyCategory] = useState<APIPathologyCategory>('neurology');

  const diagnosticVersion = diagnostic.diagnostic_versions?.[0];

  const activeStyle = (backgroundColorButton) => ({
    backgroundColor: backgroundColorButton,
    borderRadius: 16,
    fontSize: 11,
  });

  const inactiveStyle = (backgroundColorButton) => ({
    backgroundColor: backgroundColorButton,
    color: '#D9D9D9',
    borderRadius: 16,
    fontSize: 11,
  });

  const menuItems = pathologyCategoriesProps.map(pathologyCategoryProps => ({
    label: pathologyCategoryProps.label,
    Icon: (props) => (
      <div
        style={{
          height: 54,
          width: 54,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <pathologyCategoryProps.Icon {...props}/>
        {pathologyCategoryProps.menuTitle}
      </div>
    ),
    external: false,
    link: `#${pathologyCategoryProps.label}`,
    target: undefined,
    onClick: (e) => {
      e.preventDefault();
      setPathologyCategory(pathologyCategoryProps.label as APIPathologyCategory)
    },
    iconSize: {width: 24, height: 24},
    style: () => (
      pathologyCategory === pathologyCategoryProps.label
        ? activeStyle(pathologyCategoryProps.backgroundColorButton)
        : inactiveStyle(pathologyCategoryProps.backgroundColorSecondary)
    ),
  }));

  const pathologiesGrouped = groupBy(
    // @ts-ignore
    (diagnosticVersion?.computed || [])?.map(p => p.pathology as APIPathology),
    'category'
  );

  const detailsGrouped = groupBy(
    (pathologiesGrouped?.[pathologyCategory] || []).flatMap(p => p.pathology_details),
    'category'
  );

  Object.keys(detailsGrouped).forEach(key => {
    detailsGrouped[key] = detailsGrouped[key].filter((elem, index, self) => (index === self.findIndex(e => e.id === elem.id)));
  });

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <h1>Éléments cliniques validés</h1>
      <div
        style={{
          // flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          padding: '0px 16px',
          marginBottom: 10,
        }}
      >
        {
          menuItems.map((item, index) => (
            <div
              key={index}
              style={item.style()}
              onClick={() => setPathologyCategory(item.label as APIPathologyCategory)}
            >
              <item.Icon width={24} height={24}/>
            </div>
          ))
        }
      </div>
      <div style={{marginBottom: 80, width: '100%'}}>
      {
        pathologyDetailCategoriesProps.map((pathologyDetailCategoryProps, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              marginBottom: 20,
              boxSizing: 'border-box',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                alignSelf: 'flex-start',
                gap: 10,
                width: '100%',
              }}
            >
              <pathologyDetailCategoryProps.Icon
                style={{
                  // backgroundColor: pathologyDetailCategoryProps.backgroundColorPrimary,
                  borderRadius: 12,
                  padding: 4,
                }}
              />
              <SecondaryTitle>{pathologyDetailCategoryProps.title}</SecondaryTitle>
            </div>
            <List style={{width: '100%'}}>
            {
              detailsGrouped[pathologyDetailCategoryProps.label]?.filter(d => diagnosticVersion.pathology_details?.map(pd => pd.id).includes(d.id))?.map((pathologyDetail, index) => (
                <ListItem
                  key={index}
                  style={{
                    backgroundColor: pathologyCategoriesProps.find(c => c.label === pathologyCategory).backgroundColorSecondary,
                  }}
                >
                  {pathologyDetail.text}
                </ListItem>
              ))
            }
            {(detailsGrouped[pathologyDetailCategoryProps.label]?.filter(d => diagnosticVersion.pathology_details?.map(pd => pd.id).includes(d.id)) || []).length <= 0 && (
                <ListItem
                  key={index}
                  style={{
                    width: '100%',
                    backgroundColor: '#F3F3F0',
                    fontSize: 16,
                    lineHeight: '21px',
                    fontWeight: 400,
                    color: '#636360',
                    fontStyle: 'italic',
                  }}
                >
                  Aucun élément enregistré pour cette recherche
                </ListItem>
              )
            }
            </List>
          </div>
        ))
      }
      </div>
    </div>
  );
};

const DiagnosticResultPage = () => {
  const {id} = useParams();
  const {diagnostic, isLoading} = useDiagnostic({id});
  const [label, setLabel] = useState('');
  const navigate = useNavigate();
  const [screenName, setScreenName] = useState<string>('conditions');
  const [openModal, setOpenModal] = useState<string | null>(null);

  useEffect(() => {
    if (diagnostic?.id) {
      setLabel(diagnostic.label || '');
    }
  }, [diagnostic]);

  if (isLoading) {
    return null;
  }

  const diagnosticVersion = diagnostic.diagnostic_versions[0];

  const terminateDiagnostic = async () => {
    await DiagnosticAPIService.updateDiagnostic({id, label: label, diagnostic_versions: [{...diagnosticVersion, status: 'to_close'}]});

    navigate(ROUTES.DASHBOARD());
  };

  const deepenDiagnostic = async () => {
    await DiagnosticAPIService.updateDiagnostic({id, label: label, diagnostic_versions: [{...diagnosticVersion, quick: false}]});

    navigate(ROUTES.DIAGNOSTIC_PATHOLOGY_DETAILS(id));
  };

  const feedbackDiagnostic = async (feedback: string) => {
    await DiagnosticAPIService.updateDiagnostic({id, diagnostic_versions: [{...diagnosticVersion, status: 'closed', feedback}]});

    navigate(-1);
  };

  const bottomActiveStyle = () => ({
    borderRadius: 16,
  });

  const bottomInactiveStyle = () => ({
    color: '#D9D9D9',
    borderRadius: 16,
  });

  const bottomMenuItems = [
    {
      label: 'gesture',
      Icon: GestureIcon,
      external: false,
      link: undefined,
      target: undefined,
      onClick: (e) => {
        e.preventDefault();
        setScreenName('gesture')
      },
      iconSize: {width: 40, height: 40},
      style: () => (
        screenName === 'gesture'
          ? bottomActiveStyle()
          : bottomInactiveStyle()
      ),
    },
    {
      label: 'conditions',
      Icon: ConditionsIcon,
      external: false,
      link: undefined,
      target: undefined,
      onClick: (e) => {
        e.preventDefault();
        setScreenName('conditions')
      },
      iconSize: {width: 40, height: 40},
      style: () => (
        screenName === 'conditions'
          ? bottomActiveStyle()
          : bottomInactiveStyle()
      ),
    },
    {
      label: 'checklist',
      Icon: ChecklistIcon,
      external: false,
      link: undefined,
      target: undefined,
      onClick: (e) => {
        e.preventDefault();
        if (diagnosticVersion.quick && ['to_close', 'closed'].includes(diagnosticVersion.status)) {
          setOpenModal('quickDiagnosticContinue');
          return ;
        }
        setScreenName('checklist');
      },
      iconSize: {width: 40, height: 40},
      style: () => (
        screenName === 'checklist'
          ? bottomActiveStyle()
          : bottomInactiveStyle()
      ),
    },
  ];

  const date = new Date(diagnostic.created_at);
  const dateFormatted = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString().slice(-2)}`;

  return (
    <Container>
      {
        ['to_close', 'closed'].includes(diagnosticVersion.status)
          ? (
            <Header
              id={diagnostic.id}
              title={diagnostic.label || `Diagnostic du ${dateFormatted}`}
            />
          ) : (
            <TextField
              name="label"
              placeholder={"Nom de la recherche"}
              value={label}
              onChange={(event) => setLabel(event.target.value)}
              style={{marginTop: 20}}
            />
          )
      }
      {
        screenName === 'gesture' && <DrawVue diagnostic={diagnostic}/>
      }
      {
        screenName === 'conditions' && <ConditionsVue diagnostic={diagnostic}/>
      }

      {
        screenName === 'checklist' && <ChecklistVue diagnostic={diagnostic}/>
      }

      {
        ['to_close', 'closed'].includes(diagnosticVersion.status)
          ? (
            <>
              <BottomBar
                style={{position: 'fixed', bottom: -10, width: '100svw'}}
                items={bottomMenuItems}
              />
              {diagnosticVersion.status === 'to_close' && (
                <Button
                  onClick={() => {
                  }}
                  style={{
                    display: 'flex',
                    position: 'fixed',
                    justifyContent: 'space-between',
                    bottom: 100,
                    left: 20,
                    right: 20,
                    boxSizing: 'border-box',
                    padding: 8,
                  }}
                >
                  <ThumbDownIcon
                    width={24}
                    height={24}
                    style={{
                      display: 'flex',
                      padding: 12,
                      alignItems: 'flex-start',
                      gap: 10,
                      border: '1px solid #E6F728',
                      borderRadius: 9999,
                    }}
                    onClick={async () => await feedbackDiagnostic('bad')}
                  />
                  Clôturer ma recherche
                  <ThumbUpIcon
                    width={24}
                    height={24}
                    style={{
                      display: 'flex',
                      padding: 12,
                      alignItems: 'flex-start',
                      gap: 10,
                      border: '1px solid #ADE82F',
                      borderRadius: 9999,
                    }}
                    onClick={async () => await feedbackDiagnostic('good')}
                  />
                </Button>
              )}
            </>
          ) : (
            diagnosticVersion.quick
              ? (
                <div
                  style={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    width: '100%',
                    gap: 8
                  }}
                >
                  <Button
                    $outlined
                    $display="secondary"
                    onClick={async () => await terminateDiagnostic()}
                    style={{display: 'flex', flex: 1}}
                  >
                    Terminer
                  </Button>
                  <Button
                    onClick={async () => await deepenDiagnostic()}
                    style={{display: 'flex', flex: 1}}
                  >
                    Approfondir
                  </Button>
                </div>
              )
              : (
                <Button
                  onClick={async () => await terminateDiagnostic()}
                  style={{
                    display: 'block',
                    justifySelf: 'flex-end',
                    width: '100%',
                  }}
                >
                  Terminer
                </Button>
              )
          )
      }
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={() => {
          setScreenName('checklist');
          setOpenModal(null);
          navigate(ROUTES.DIAGNOSTICS(id));
        }}
        translationKey={`diagnosticResult.${openModal}`}
      />
    </Container>
  );
};

export default DiagnosticResultPage;